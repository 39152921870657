<template>
  <div class="page-main-bg" id="clientsPage">
    <v-container :class="['main-container team-container position-relative']">
      <!-- --------------------------------- -->
      <!-- ---------Breadcrumbs------------- -->
      <!-- --------------------------------- -->

      <v-breadcrumbs :items="breadcrumbs" class="pa-1 pt-3 breadcrumb">
        <template v-slot:divider>
          <img
            src="@/assets/img/svgs/fi-br-angle-small-down.svg"
            loading="lazy"
          />
        </template>
      </v-breadcrumbs>

      <!-- -------Title------- -->
      <h3 class="my-4">جمعية مجمع العزيزية</h3>

      <v-container>
        <!-- -------ROW 1------ -->
        <v-row class="gap-15">
          <!------------------------------------------->
          <!---------Association details info---------->
          <!------------------------------------------->

          <v-col cols="12" md="8" class="px-0">
            <AssociationDetailsInfoCard @edit="openModal" />
          </v-col>

          <!---------------------------------------->
          <!---------Association contracts---------->
          <!---------------------------------------->

          <v-col class="px-0">
            <AssociationContracts />
          </v-col>
        </v-row>

        <!-- -------ROW 2------ -->
        <v-row class="gap-15">
          <!-- -------------------------------------- -->
          <!-- -----Associations Details Actions----- -->
          <!-- -------------------------------------- -->

          <v-col cols="12" md="8" class="px-0">
            <AssociationsDetailsActions />
          </v-col>

          <!-- ------------------------------ -->
          <!-- -----Associations Members----- -->
          <!-- ------------------------------ -->

          <v-col class="px-0">
            <AssociationsMembers />
          </v-col>
        </v-row>
      </v-container>

      <!----------------------------->
      <!------ Add client modal ----->
      <!----------------------------->

      <!-- TODO use a prop DATA for send all data to a component -->
      <AddAssoicationModal
        v-if="dialogState"
        :dialogState="dialogState"
        @close-client-dialog="closeModal"
      />
    </v-container>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import AssociationDetailsInfoCard from '@/components/associations/AssociationDetailsInfoCard'
import AssociationsDetailsActions from '@/components/associations/AssociationsDetailsActions'
import AssociationsMembers from '@/components/associations/AssociationsMembers'
import AssociationContracts from '@/components/associations/AssociationContracts'
import '@/styles/material-dashboard/_properties.scss'
import '@/styles/material-dashboard/_team.scss'

export default {
  components: {
    AddAssoicationModal: () =>
      import('@/components/modals/AddAssoicationModal'),
    AssociationDetailsInfoCard,
    AssociationsDetailsActions,
    AssociationsMembers,
    AssociationContracts
  },
  data() {
    return {
      dialogState: false,
      breadcrumbs: [
        {
          text: 'اتحاد الملاك',
          disabled: false,
          exact: true,
          link: true,
          to: { path: '/dashboard/associations' }
        }
      ]
    }
  },
  mounted() {
    this.$emit('updateInformativeTip', this.$route?.meta?.informativeTip)
  },
  computed: {
    ...mapState('accountState', ['currentEnv', 'user'])
  },
  methods: {
    ...mapMutations('appState', ['toggleDrawer', 'addNotification']),
    openModal() {
      this.dialogState = true
    },
    closeModal() {
      this.dialogState = false
    },
    metaInfo() {
      return {
        title: 'تفاصيل الجمعية'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/associations/associations.scss';

.v-icon.v-icon--dense {
  font-size: 25px !important;
}
</style>
